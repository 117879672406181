:root {
    --top-menu-height: 4em;
}

.top-menu {
    height: var(--top-menu-height);
}
.custom-grid {
    width: 100%;
    display: grid;
    grid: var(--top-menu-height) / 1fr;
    grid-template-areas:  "menu"
                          "maincontent";
    min-height: 100vh;
}

.menu {
    grid-area: menu;
    z-index: 100;
}

.main-content {
    grid-area: maincontent;
}

.parent {
    display: inline-flex;
}

.side {
    transition: width 0.3s;
    width: 14em !important;
    overflow: hidden;
    padding-top: var(--top-menu-height);
    z-index: 99;
}

.contentMain {
    position: absolute;
    right: 0;
    transition: all 0.3s;
    padding: 10px;
    width: calc(100% - 14em);
    background-color: #f9fafb;
}

.version-footer {
    color: #ffffff;
    position: fixed;
    bottom: calc(4em + 2vh);
    left: 2vh;
    z-index: 500;
}

.ui.selectable.table tr.negative:hover,
.ui.table tr td.selectable.negative:hover,
.ui.selectable.table tr:hover td.negative {
    background: #e3b0b0 !important;
    color: #ffffff !important;
}

.ui.table td.negative, .ui.table tr.negative {
    background: #ec9d9d !important;
    color: #ffffff !important;
}

.ui.selectable.table tr.positive:hover,
.ui.table tr td.selectable.positive:hover,
.ui.selectable.table tr:hover td.positive {
    background: #84cf73 !important;
    color: #ffffff !important;
}

.ui.table td.positive, .ui.table tr.positive {
    background: #69cc24 !important;
    color: #ffffff !important;
}

.ui.selectable.table tr.warning:hover,
.ui.table tr td.selectable.warning:hover,
.ui.selectable.table tr:hover td.warning {
    background: #f5e4a2 !important;
    color: #ffffff !important;
}

.ui.table td.warning, .ui.table tr.warning {
    background: #f7e482 !important;
    color: #ffffff !important;
}

.modal-dimensions {
    width: 90% !important;
    height: 90% !important;
}

.overflow-scroll {
    overflow: scroll;
}

.grey-text {
    color: grey;
}

.index-header-bottom-align {
    margin-top: 1.5rem;
}

.index-header-dropdown {
    margin-left: 0.6rem;
}

.fit-content {
    min-width: 100% !important;
}

.page-header {
    height: 1.5rem;
}

.index-header {
    height: 2.5rem;
}

.pull-left {
    float: left;
}

.pull-right {
    float: right;
}

.center-text {
    text-align: center;
}

.clickable {
    cursor: pointer;
}

.footer-records {
    float: left;
    margin-top: 0.625rem;
}

.modals {
    justify-content: center !important;
}

.sidebar-overrides {
    height: calc(100vh - 4em);
    position: fixed;
    top: 4em;
}

.enable-fixed {
    transform: none !important;
}

.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}